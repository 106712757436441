import React, {useEffect} from "react"
import Header from "../../components/header"
import Trade from "../../components/trade"
import { FaTrophy } from 'react-icons/fa'
import teamsData from "../../../static/data/teams.json"
import tradesData from "../../../static/data/trades.json"
import { Chart } from 'chart.js'
import {Helmet} from "react-helmet"

function Page({teams}) {
  const season = "2019";
  const teamNames2 = teamsData.map((team) => team.seasons[season].name);
  const trades = tradesData[0][season].trades;

  useEffect(() => {
    const teams = teamsData;

    let teamNames = [];
    let teamColors = [];
    let teamPFs = [];
    let teamPPs = [];
    let teamPAs = [];
    let teamAvgScores = [];
    let teamAboveAvgPercs = [];
    let teamTrans = [];
    let teamMsgs = [];
    let teamPercPP = [];
    let teamFaabSpent = [];
    let teamTier1QBs = [];
    let teamTier1RBs = [];
    let teamTier1WRs = [];
    let teamTier1TEs = [];
    let teamTier1DSTs = [];

    for (var i = 0; i < teams.length; i++) {
      const teamSeasonData = teams[i].seasons[season];

      teamNames.push(teamSeasonData.name);
      teamColors.push(teams[i].color);
      teamPFs.push(teamSeasonData.pointsFor);
      teamPPs.push(teamSeasonData.pointsPossible);
      teamPAs.push(teamSeasonData.pointsAgainst);
      teamAvgScores.push(teamSeasonData.avgScore);
      teamAboveAvgPercs.push(teamSeasonData.aboveAvgPerc);
      teamTrans.push(teamSeasonData.transactions);
      teamMsgs.push(teamSeasonData.messages);
      teamPercPP.push(((teamSeasonData.pointsFor / teamSeasonData.pointsPossible) * 100).toFixed(2));
      teamFaabSpent.push(teamSeasonData.faabSpent);

      teamTier1QBs.push(teamSeasonData.tier1_QB.length);
      teamTier1RBs.push(teamSeasonData.tier1_RB.length);
      teamTier1WRs.push(teamSeasonData.tier1_WR.length);
      teamTier1TEs.push(teamSeasonData.tier1_TE.length);
      teamTier1DSTs.push(teamSeasonData.tier1_DST.length);
    }

    new Chart(document.getElementById("chartPF"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamPFs,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamPPs,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPercPP"), {
      type: 'polarArea',
      data: {
        datasets: [{
          data: teamPercPP,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartPA"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamPAs,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartTrades"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].tradeCount),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              precision: 0
            }
          }]
        }
      }
    });

    new Chart(document.getElementById("chartAvgScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamAvgScores,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    // doesn't pull from array
    new Chart(document.getElementById("chartHighestScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: [
            '184.36',
            '182.86',
            '176.90',
            '173.98',
            '163.50',
            '161.20',
            '159.46',
            '159.46',
            '156.74',
            '156.72'
          ],
          backgroundColor: [
            teamColors[7],
            teamColors[4],
            teamColors[3],
            teamColors[9],
            teamColors[4],
            teamColors[0],
            teamColors[7],
            teamColors[6],
            teamColors[0],
            teamColors[0]
          ],
        }],
        labels: [
          teamNames[7] + ' - Week 2',
          teamNames[4] + ' - Week 14',
          teamNames[3] + ' - Week 8',
          teamNames[9] + ' - Week 9',
          teamNames[4] + ' - Week 5',
          teamNames[0] + ' - Week 12',
          teamNames[7] + ' - Week 6',
          teamNames[6] + ' - Week 8',
          teamNames[0] + ' - Week 3',
          teamNames[0] + ' - Week 9'
        ]
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAboveAvgPerc"), {
      type: 'polarArea',
      data: {
        datasets: [{
          data: teamAboveAvgPercs,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartTrans"), {
        type: 'doughnut',
        data: {
          datasets: [{
            data: teamTrans,
            backgroundColor: teamColors,
          }],

          labels: teamNames
        },
        options: {
          legend: {
            display: true,
            position: 'left'
          },
          responsive: true,
        }
    });

    new Chart(document.getElementById("chartFaabSpent"), {
      type: 'doughnut',
      data: {
        datasets: [{
          data: teamFaabSpent,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartMsgs"), {
      type: 'doughnut',
      data: {
        datasets: [{
          data: teamMsgs,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartTier1"),{
      type:"horizontalBar",
      data:{
        labels: teamNames,
        datasets:[
          {
            label: "QB",
            data: teamTier1QBs,
            backgroundColor: "#C62828"
          },
          {
            label: "RB",
            data: teamTier1RBs,
            backgroundColor: "#2E7D32"
          },
          {
            label: "WR",
            data: teamTier1WRs,
            backgroundColor: "#1565C0"
          },
          {
            label: "TE",
            data: teamTier1TEs,
            backgroundColor: "#F9A825"
          },
          {
            label: "D/ST",
            data: teamTier1DSTs,
            backgroundColor: "#6A1B9A"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    });
  }, [teams]) 

  return (
    <React.Fragment>
      <Helmet>
        <title>2019 Season Recap | Chode Dynasty</title>
      </Helmet>
      
      <Header />
      <main>
        <div className="row">
          <div className="inner">
            <h1>2019 Season Recap</h1>
            
            <div className="cols-2">
              <div>
                <h2>Final Standings</h2>
                <ol className="standings">
                  <li>
                    <span className="label">1<sup>st</sup></span> Jackson Jacks Off (9-5)
                    <div className="trophy place-1"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">2<sup>nd</sup></span> Get Nae Naed (10-4)
                    <div className="trophy place-2"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">3<sup>rd</sup></span> Robo Blowies (9-5)
                    <div className="trophy place-3"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">4<sup>th</sup></span> Get Whipped (9-5)
                  </li>
                  
                  <li>
                    <span className="label">5<sup>th</sup></span> jhoffman (8-6)
                  </li>
                  
                  <li>
                    <span className="label">6<sup>th</sup></span> Lil' Jergz (8-6)
                  </li>
                  
                  <li>
                    <span className="label">7<sup>th</sup></span> Dinguses (7-7)
                  </li>
                  
                  <li>
                    <span className="label">8<sup>th</sup></span> Super Sportsball Bois (6-8)
                  </li>
                  
                  <li>
                    <span className="label">9<sup>th</sup></span> Ckbenz (3-11)
                  </li>
                  
                  <li>
                    <span className="label">10<sup>th</sup></span> frankakatank (1-13)
                  </li>
                </ol>
              </div>

              <div>
                <h2>Champion Roster</h2>
                <table className="styled champ">
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Player</th>
                      <th>Points</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>QB</td>
                      <td>Ryan Fitzpatrick (MIA)</td>
                      <td>39.66</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>Aaron Jones (GB)</td>
                      <td>27.50</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>Saquon Barkley (NYG)</td>
                      <td>41.90</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>Will Fuller (HOU)</td>
                      <td>2.10</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>Tyler Lockett (SEA)</td>
                      <td>1.70</td>
                    </tr>
                    <tr>
                      <td>TE</td>
                      <td>Noah Fant (DEN)</td>
                      <td>2.00</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>Davante Adams (GB)</td>
                      <td>15.10</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>Keenan Allen (LAC)</td>
                      <td>9.10</td>
                    </tr>
                    <tr>
                      <td>DST</td>
                      <td>Atlanta Falcons</td>
                      <td>11.00</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td><strong>150.06</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>

        <div id="awards" className="row">
          <div className="inner">
            <h2>Awards</h2>
            <div className="cols-2">
              <div>
                <h3><span role="img" aria-label="rocket">🚀</span> Highest Scorer</h3>
                <h4>Get Nae Naed</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="pile of poo">💩</span>Lowest Scorer</h3>
                <h4>frankakatank</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="four-leaf clover">🍀</span>Best Luck</h3>
                <div className="desc">(least points against)</div>
                <h4>Get Whipped</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="face with head-bandage">🤕</span>Worst Luck</h3>
                <div className="desc">(most points against)</div>
                <h4>Ckbenz</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="fire">🔥</span>Best Manager</h3>
                <div className="desc">(highest percentage of possible points scored)</div>
                <h4>jhoffman</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="thinking face">🤔</span>Worst Manager</h3>
                <div className="desc">(lowest percentage of possible points scored)</div>
                <h4>Lil' Jergz</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points For</h2>
            <canvas id="chartPF"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Possible</h2>
            <canvas id="chartPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Possible Points Scored</h2>
            <canvas id="chartPercPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Against</h2>
            <canvas id="chartPA"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Team Average Score</h2>
            <canvas id="chartAvgScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Games with Above Average Score</h2>
            <canvas id="chartAboveAvgPerc"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Highest Scores</h2>
            <canvas id="chartHighestScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Transactions</h2>
            <canvas id="chartTrans"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>FAAB Spent (out of $100)</h2>
            <canvas id="chartFaabSpent"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Chat Messages</h2>
            <canvas id="chartMsgs"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Top Scoring Players by Position</h2>
            
            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Quarterback</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>L. Jackson</td>
                      <td>480.2</td>
                      <td>Super Sportsball Bois</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>D. Prescott</td>
                      <td>393.3</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>R. Wilson</td>
                      <td>385.1</td>
                      <td>Robo Blowies</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>D. Watson</td>
                      <td>364</td>
                      <td>Get Whipped</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>J. Winston</td>
                      <td>360.9</td>
                      <td>Robo Blowies</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>P. Mahomes</td>
                      <td>336</td>
                      <td>Super Sportsball Bois</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>A. Rodgers</td>
                      <td>326.4</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>K. Murray</td>
                      <td>323.3</td>
                      <td>Get Whipped</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>C. Wentz</td>
                      <td>319.9</td>
                      <td>Get Nae Naed</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>J. Allen</td>
                      <td>316.1</td>
                      <td>Dinguses</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>M. Ryan</td>
                      <td>311.8</td>
                      <td>Jackson Jacks Off</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>T. Brady</td>
                      <td>309.2</td>
                      <td>Get Nae Naed</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Running Back</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>C. McCaffrey</td>
                      <td>415.2</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>A. Jones</td>
                      <td>288.8</td>
                      <td>Jackson Jacks Off</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>E. Elliott</td>
                      <td>284.7</td>
                      <td>Get Nae Naed</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>D. Henry</td>
                      <td>282.1</td>
                      <td>Get Nae Naed</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>D. Cook</td>
                      <td>261.9</td>
                      <td>Robo Blowies</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>A. Ekeler</td>
                      <td>260</td>
                      <td>Get Nae Naed</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>N. Chubb</td>
                      <td>235.7</td>
                      <td>Robo Blowies</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>M. Ingram</td>
                      <td>229</td>
                      <td>Lil' Jergz</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>L. Fournette</td>
                      <td>222.6</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>S. Barkley</td>
                      <td>218.6</td>
                      <td>Jackson Jacks Off</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>J. Mixon</td>
                      <td>207.9</td>
                      <td>Lil' Jergz</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>C. Carson</td>
                      <td>207.1</td>
                      <td>Get Nae Naed</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Wide Receiver</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>M. Thomas</td>
                      <td>299.1</td>
                      <td>Lil' Jergz</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>C. Godwin</td>
                      <td>242.1</td>
                      <td>Robo Blowies</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>J. Jones</td>
                      <td>228.1</td>
                      <td>Get Nae Naed</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>C. Kupp</td>
                      <td>225</td>
                      <td>Get Whipped</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>K. Golladay</td>
                      <td>222</td>
                      <td>Get Nae Naed</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>D. Hopkins</td>
                      <td>220</td>
                      <td>Robo Blowies</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>K. Allen</td>
                      <td>214</td>
                      <td>Jackson Jacks Off</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>D. Parker</td>
                      <td>213.2</td>
                      <td>Get Whipped</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>A. Cooper</td>
                      <td>208.5</td>
                      <td>Super Sportsball Bois</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>A. Robinson</td>
                      <td>207.4</td>
                      <td>Jackson Jacks Off</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>J. Edelman</td>
                      <td>206.8</td>
                      <td>Lil' Jergz</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>M. Evans</td>
                      <td>205.2</td>
                      <td>Dinguses</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Tight End</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>T. Kelce</td>
                      <td>206.3</td>
                      <td>Get Nae Naed</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>G. Kittle</td>
                      <td>188</td>
                      <td>Robo Blowies</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>D. Waller</td>
                      <td>176.5</td>
                      <td>Dinguses</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>M. Andrews</td>
                      <td>174.7</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Z. Ertz</td>
                      <td>170.6</td>
                      <td>Lil' Jergz</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>A. Hooper</td>
                      <td>156.4</td>
                      <td>Get Nae Naed</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>J. Cook</td>
                      <td>147.5</td>
                      <td>Super Sportsball Bois</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>T. Higbee</td>
                      <td>128.9</td>
                      <td>Jackson Jacks Off</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>H. Henry</td>
                      <td>124.7</td>
                      <td>Super Sportsball Bois</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>D. Goedert</td>
                      <td>119.7</td>
                      <td>Lil' Jergz</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>M. Gesicki</td>
                      <td>112.5</td>
                      <td>Lil' Jergz</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>J. Witten</td>
                      <td>108.9</td>
                      <td>Super Sportsball Bois</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Defense / Special Teams</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Patriots</td>
                      <td>240</td>
                      <td>Get Nae Naed</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Steelers</td>
                      <td>204</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>49ers</td>
                      <td>190</td>
                      <td>Dinguses</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Ravens</td>
                      <td>165</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Vikings</td>
                      <td>152</td>
                      <td>Super Sportsball Bois</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Bills</td>
                      <td>150</td>
                      <td>Get Whipped</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Buccaneers</td>
                      <td>148</td>
                      <td>Robo Blowies</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Saints</td>
                      <td>141</td>
                      <td>Lil' Jergz</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Rams</td>
                      <td>141</td>
                      <td>Get Whipped</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Jets</td>
                      <td>140</td>
                      <td>Lil' Jergz</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Chiefs</td>
                      <td>131</td>
                      <td>Robo Blowies</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Titans</td>
                      <td>128</td>
                      <td>Free Agent</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Tier 1 Player Management</h2>
            <div className="chart-desc">(how many tier 1 players each team had)</div>
            <canvas id="chartTier1"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Reward Winners</h2>
            <table className="styled weekly-reward">
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Title</th>
                  <th>Challenge</th>
                  <th>Winner</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ready Set Go</td>
                  <td>Team with the highest score</td>
                  <td>jhoffman - 146.6</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Hero to Zero</td>
                  <td>Team with the biggest drop in score from Week 1 to Week 2</td>
                  <td>frankakatank - 55.9 drop in score</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Air It Out</td>
                  <td>Team with the QB with the longest pass</td>
                  <td>Super Sportsball Bois - Mahomes had a 83 yard TD pass to Hardman</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Gotta Catch Em All</td>
                  <td>Team with the WR with the most receptions</td>
                  <td>Super Sportsball Bois - Robert Woods (13)</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Runt of the Litter</td>
                  <td>Winning team with the lowest scoring starter</td>
                  <td>Robo Blowies - LeSean McCoy (0.30 pts)</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Got There</td>
                  <td>Team that beats its opponent by the smallest margin of victory</td>
                  <td>Jackson Jacks Off - 3.56 pts</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Halftime</td>
                  <td>Team with the most total points after 7 weeks</td>
                  <td>Get Nae Naed - 988 pts</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Blackjack</td>
                  <td>Team with a starter closest to 21 points without going over</td>
                  <td>Get Nae Naed - Patriots D/ST (21 pts)</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>The Purge</td>
                  <td>Subtract last week's score from this weeks, the team with the highest remaining score wins</td>
                  <td>jhoffman - 77.3 pts</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Right On Target</td>
                  <td>Team closest to their projected score (over OR under)</td>
                  <td>Get Nae Naed - 3.25 pts off of projected</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>At Least You Tried</td>
                  <td>Highest scoring losing team</td>
                  <td>frankakatank - 107.76 pts</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Magic Legs</td>
                  <td>Team with the RB with the most rushing yards</td>
                  <td>Get Nae Naed - Derrick Henry (159 yards)</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>End Zone</td>
                  <td>Team with the most offensive touchdowns scored</td>
                  <td>Ckbenz - 8 TDs</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id="trades" className="row">
          <div className="inner">
            <h2>Trades</h2>

            <h3>Total Trades - {trades.length}</h3>
            <canvas id="chartTrades"></canvas>

            <h3>Trade Details</h3>
            <div id="trades-details">
              {trades.map((data,id)=>{
                return <Trade key={id} data={data} teamNames={teamNames2} />
              })}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Page;